<template>
  <div class="w-100 my-5">
    <div v-if="drinkOrderList">
      <div class="row mx-auto">
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('sales_amount') }}</span>
          <span class="mt-3 font-bold font-50 color-blue">{{ getPriceFormat(salesAmount) }}</span>
          <div class="d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('paid_online') }}</span>
            <span class="font-15 color-blue">{{ getPriceFormat(paidOnline) }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('paid_by_card') }}</span>
            <span class="font-15 color-blue">{{ getPriceFormat(paidCard) }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('paid_by_cash') }}</span>
            <span class="font-15">{{ getPriceFormat(paidCash) }}</span>
          </div>
        </div>
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('tip') }}</span>
          <span class="mt-3 font-bold font-50 color-blue">{{ getPriceFormat(tipAmount) }}</span>
          <div class="d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('paid_online') }}</span>
            <span class="font-15 color-blue">{{ getPriceFormat(tipOnline) }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('paid_by_card') }}</span>
            <span class="font-15 color-blue">{{ getPriceFormat(tipCard) }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span class="font-12 alpha-07">{{ $t('paid_by_cash') }}</span>
            <span class="font-15">{{ getPriceFormat(tipCash) }}</span>
          </div>
        </div>
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="fa fa-euro-sign font-15 color-red">&nbsp;&nbsp;&nbsp;{{ $t('handling_fee') }}</span>
          <span class="mt-3 font-bold font-50 color-red">{{ getPriceFormat(handlingFee) }}</span>
        </div>
      </div>
      <div class="row mx-auto">
        <div class="col-sm m-2 box-white row vertical-center">
          <div class="col-sm my-1 vertical-center" v-if="userInfo">
            <img class="circle-image-30" :src="userInfo.thumbnailUrl || userInfo.photoUrl || assets.profile" />
            <span class="ml-2 font-15 font-bold">{{ userInfo.userName }}</span>
          </div>
          <div class="col-sm my-1 vertical-center" v-else-if="eventInfo">
            <img :width="60" :height="40" :src="eventInfo.thumbnailUrl || eventInfo.photoUrl" />
            <span class="ml-2 font-15 font-bold">{{ eventInfo.name }}</span>
          </div>
          <div class="col-sm my-1 vertical-center">
            <span class="font-bold font-15 alpha-07">{{ $t('orders') }}:</span>
            <span class="mx-5 font-bold font-20 color-blue">{{ items.length }}</span>
          </div>
          <DatePicker
            class="col-sm my-1"
            v-model="selectedMonth"
            type="month"
            @change="onMonthChanged"
            :placeholder="$t('select_month')"
          />
          <DatePicker
            class="col-sm my-1"
            v-model="dateRange"
            type="date"
            range
            @change="onDateRangeChanged"
            :placeholder="$t('select_date_range')"
          />
          <div :class="`col-sm my-1 vertical-center ${isMobile ? '' : 'justify-content-end'}`">
            <input class="w-75" v-model="keyword" />
            <v-btn class="ml-2" icon small @click="keyword = ''" :disabled="!keyword">
              <i :class="`flaticon-circle ${keyword.length > 0 ? 'color-blue' : ''}`"></i>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="row mx-auto" v-for="(item, index) in items.slice(0, Math.min(pageIndex * PAGE_LIMIT_10, items.length))" :key="(item, index)">
        <div class="col-sm m-2 p-0 box-white row">
          <div class="col-sm d-flex flex-column">
            <div class="my-1 vertical-center" v-if="!userInfo">
              <img class="circle-image-30" :src="item.organizerPhotoUrl" />
              <span class="ml-2 ellipsis font-bold font-12" :title="item.organizerName">{{ item.organizerName }}</span>
            </div>
            <img width="200px" :src="item.eventPhotoUrl" />
            <span class="my-1 w-250px ellipsis font-bold font-12" :title="item.eventName">{{ item.eventName }}</span>
          </div>
          <div class="vertical-center">
            <div class="my-5 div-divider-h" v-if="isMobile" />
            <div class="mx-3 div-divider-v" v-else />
          </div>
          <div class="col-sm-4 d-flex flex-column">
            <span class="m-2 font-12" v-if="hostname === 'localhost'">ID: {{ item.orderId }}</span>
            <span class="mt-3 font-bold font-15 color-red" v-if="item.tag">{{ $t('tag') }}: {{ item.tag }}</span>
            <span class="my-3 font-bold font-15 color-drink">Order No: {{ getDecimalFormat(item.orderNumber) }}</span>
            <div class="mt-2 d-flex justify-content-between vertical-center" v-for="(drink, index2) in item.drinks" :key="(drink, index2)">
              <span class="w-50 ellipsis font-12" :title="drink.name">{{ drink.name }}</span>
              <span class="ml-auto font-12">{{ `${getPriceFormat(drink.price)} * ${drink.quantity} = ${getPriceFormat(drink.price * drink.quantity)}` }}</span>
            </div>
            <div v-if="!isMobile">&nbsp;</div>
          </div>
          <div class="vertical-center">
            <div class="my-5 div-divider-h" v-if="isMobile" />
            <div class="mx-3 div-divider-v" v-else />
          </div>
          <div class="col-sm-3 d-flex flex-column">
            <div class="my-1 vertical-center">
              <img class="circle-image-30" :src="item.buyerPhotoUrl" />
              <span class="ml-2 ellipsis font-12 font-bold" :title="item.buyerName">{{ item.buyerName }}</span>
            </div>
            <span :class="`mt-2 ellipsis font-12 ${item.deviceTypeColor}`" :title="item.deviceType" v-if="item.deviceType">{{ item.deviceType }}</span>
            <div class="mt-5 d-flex justify-content-between">
              <span class="font-15">{{ $t('total_price') }}</span>
              <span class="ml-2 font-bold font-15">{{ getPriceFormat(item.totalPrice) }}</span>
            </div>
            <div class="mt-2 d-flex justify-content-between" v-if="item.tipPrice">
              <span class="font-12">{{ $t('tip') }}</span>
              <span class="ml-2 font-12">{{ getPriceFormat(item.tipPrice) }}</span>
            </div>
            <div class="mt-2 d-flex justify-content-between" v-if="item.handlingFee">
              <span class="font-12">{{ $t('handling_fee') }}</span>
              <span class="ml-2 font-12">{{ getPriceFormat(item.handlingFee) }}</span>
            </div>
          </div>
          <div class="vertical-center">
            <div class="my-5 div-divider-h" v-if="isMobile" />
            <div class="mx-3 div-divider-v" v-else />
          </div>
          <div class="col-sm d-flex flex-column">
            <div class="my-2 d-flex justify-content-between vertical-center" v-if="hostname === 'localhost' && item.eventId.startsWith(TEST_PREFIX)">
              <span class="my-2 font-bold font-15 color-red">{{ item.orderId.startsWith(PAYMENT_TYPE_PAYPAL_SANDBOX) || item.orderId.startsWith(PAYMENT_TYPE_STRIPE_TEST) ? 'Sandbox' : 'Test' }}</span>
              <v-btn icon small @click="deleteDrinkOrderInfoTask(item.orderId, item.eventId)">
                <i class="flaticon-delete color-red"></i>
              </v-btn>
            </div>
            <span class="mt-5 font-bold font-15 color-blue">{{ $t(item.orderId.startsWith(PAYMENT_TYPE_PAYPAL_SANDBOX) || item.orderId.startsWith(PAYMENT_TYPE_STRIPE_TEST) ? 'paid_online' : item.paymentMethod === PAYMENT_TYPE_CASH ? 'paid_by_cash' : item.paymentMethod === PAYMENT_TYPE_CARD ? 'paid_by_card' : 'paid') }}</span>
            <div class="mt-2 d-flex flex-column" v-if="hostname === 'localhost' && item.type">
              <span class="font-12 font-bold color-red">{{ item.type }}</span>
              <span class="ml-auto font-12">{{ item.orderId }}</span>
            </div>
            <div class="mt-2 d-flex vertical-center">
              <img height="30px" :src="item.orderId.startsWith(PAYMENT_TYPE_PAYPAL) ? assets.paypal : assets.stripe" v-if="item.orderId.startsWith(PAYMENT_TYPE_PAYPAL) || item.orderId.startsWith(PAYMENT_TYPE_STRIPE)" />
              <img height="30px" :src="item.paymentMethod === PAYMENT_TYPE_CASH ? assets.cash : assets.credit_card" v-else-if="item.paymentMethod" />
              <img class="ml-2" height="30px" :src="assets[item.paymentMethod]" v-if="item.orderId.startsWith(PAYMENT_TYPE_STRIPE) && item.paymentMethod" />
              <span class="ml-auto font-12">{{ getDateStringFromTimestamp(item.createdAt) }}</span>
            </div>
            <div class="mt-2" v-if="isMobile">&nbsp;</div>
          </div>
        </div>
      </div>
      <div class="my-5 d-flex justify-content-center" v-if="items.length / PAGE_LIMIT_10 > pageIndex">
        <v-btn text small @click="pageIndex++">
          <span>Load More</span>
        </v-btn>
      </div>
      <div class="py-40 center" v-if="(items.length === 0)">
        <span class="font-20 alpha-07">{{ $t('no_data_found') }}</span>
      </div>
    </div>
    <div class="py-40 center" v-else>
      <p>{{ $t('please_wait') }}</p>
      <b-spinner type="grow"></b-spinner>
    </div>
  </div>
</template>


<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../main';
import { getPriceFormat, getDateStringFromTimestamp, getDateStringFromDate, showLoading, getDecimalFormat } from '../../../functions';

import profile from '@/assets/image/profile.jpg';
import paypal from '@/assets/image/paypal.png';
import stripe from '@/assets/image/stripe.png';
import cash from '@/assets/image/cash.png';
import credit_card from '@/assets/image/credit_card.png';
import card from '@/assets/image/card.png';
import sepa from '@/assets/image/sepa.png';
import sepa_debit from '@/assets/image/sepa.png';
import sofort from '@/assets/image/sofort.png';
import klarna from '@/assets/image/klarna.png';
import giropay from '@/assets/image/giropay.png';

export default {
  name: 'DrinkOrderList',
  components: {
    DatePicker
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    userList() {
      return this.$store.state.userList || [];
    },
    userInfo() {
      if (this.$route.params.userId) {
        return this.userList.find(element => element.userId === this.$route.params.userId);
      } else {
        return null;
      }
    },
    eventList() {
      return this.$store.state.eventList || [];
    },
    eventInfo() {
      if (this.$route.params.eventId) {
        return this.eventList.find(element => element.eventId === this.$route.params.eventId);
      } else {
        return null;
      }
    },
    drinkOrderList() {
      return this.$store.state.drinkOrderList;
    },
    params() {
      return this.$route.params;
    }
  },
  watch: {
    params() {
      if (this.keyword) {
        this.keyword = '';
      } else {
        this.refreshData();
      }
    },
    eventList(newList, oldList) {
      if (oldList.length !== newList.length) {
        this.refreshData();
      }
    },
    userList(newList, oldList) {
      if (oldList.length !== newList.length) {
        this.refreshData();
      }
    },
    drinkOrderList(newList, oldList) {
      const oldCount = oldList ? oldList.length : 0;
      const newCount = newList ? newList.length : 0;
      if (oldCount !== newCount) {
        this.refreshData();
      }
    },
    keyword() {
      this.refreshData();
    }
  },
  data() {
    return {
      assets: {
        profile,
        paypal,
        stripe,
        credit_card,
        cash,
        card,
        sepa,
        sepa_debit,
        sofort,
        klarna,
        giropay
      },
      hostname: window.location.hostname,
      selectedMonth: null,
      dateRange: null,
      salesAmount: 0,
      paidOnline: 0,
      paidCard: 0,
      paidCash: 0,
      tipAmount: 0,
      tipOnline: 0,
      tipCard: 0,
      tipCash: 0,
      handlingFee: 0,
      pageIndex: 1,
      cacheEventList: [],
      cacheUserList: [],
      items: [],
      keyword: ''
    }
  },
  mounted() {
    this.setDateRange();
    this.refreshData();
  },
  methods: {
    getPriceFormat,
    getDecimalFormat,
    getDateStringFromTimestamp,
    setDateRange() {
      if (!this.$route.params.userId && !this.$route.params.eventId) {
        const startDate = new Date();
        const endDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        this.dateRange = [startDate, endDate];
      }
    },
    onMonthChanged(value, type) {
      if (value) {
        this.dateRange = [null, null];
      } else {
        this.setDateRange();
      }
      this.refreshData();
    },
    onDateRangeChanged(value, type) {
      if (value && value.length === 2 && value[0] && value[1]) {
        this.selectedMonth = null;
      } else {
        this.selectedMonth = new Date();
      }
      this.refreshData();
    },
    refreshData() {
      if (this.drinkOrderList && this.userList && this.eventList) {
        var salesAmount = 0;
        var paidOnline = 0;
        var paidCard = 0;
        var paidCash = 0;
        var tipAmount = 0;
        var tipOnline = 0;
        var tipCard = 0;
        var tipCash = 0;
        var handlingFee = 0;
        const items = [];
        const keyword = this.keyword.toLowerCase();
        this.drinkOrderList.filter(element => this.filterDrinkOrder(element)).forEach(orderInfo => {
          const buyerInfo = this.getUserInfo(orderInfo.buyerId || orderInfo.organizerId);
          const organizerInfo = this.getUserInfo(orderInfo.organizerId);
          const eventInfo = this.getEventInfo(orderInfo.eventId);
          if (buyerInfo && organizerInfo && eventInfo && (orderInfo.orderNumber.toString().includes(keyword) || eventInfo.name.toLowerCase().includes(keyword) || buyerInfo.userName.toLowerCase().includes(keyword) || organizerInfo.userName.toLowerCase().includes(keyword))) {
            const item = JSON.parse(JSON.stringify(orderInfo));
            item['buyerName'] = orderInfo.buyerId ? buyerInfo.userName : orderInfo.employeeName || buyerInfo.userName;
            item['buyerPhotoUrl'] = !orderInfo.buyerId && orderInfo.employeeName ? this.assets.profile : buyerInfo.thumbnailUrl || buyerInfo.photoUrl || this.assets.profile;
            item['organizerName'] = organizerInfo.userName;
            item['organizerPhotoUrl'] = organizerInfo.thumbnailUrl || organizerInfo.photoUrl || this.assets.profile;
            item['eventName'] = eventInfo.name;
            item['eventPhotoUrl'] = eventInfo.thumbnailUrl || eventInfo.photoUrl;
            if (orderInfo.buyerId && window.location.hostname === 'localhost') {
              item['deviceType'] = buyerInfo.deviceType;
              item['deviceTypeColor'] = buyerInfo.deviceTypeColor;
            }
            salesAmount += orderInfo.drinkPrice;
            tipAmount += orderInfo.tipPrice;
            handlingFee += orderInfo.handlingFee;
            if (orderInfo.paymentMethod === this.PAYMENT_TYPE_CARD) {
              paidCard += orderInfo.drinkPrice;
              tipCard += orderInfo.tipPrice;
            } else if (orderInfo.paymentMethod === this.PAYMENT_TYPE_CASH) {
              paidCash += orderInfo.drinkPrice;
              tipCash += orderInfo.tipPrice;
            } else if (orderInfo.referenceId) {
              paidOnline += orderInfo.drinkPrice;
              tipOnline += orderInfo.tipPrice;
            }
            items.push(item);
          }
        });
        items.sort((a, b) => (a.createdAt.seconds < b.createdAt.seconds) ? 1 : -1);
        this.pageIndex = 1;
        this.items = items;
        this.salesAmount = salesAmount;
        this.paidOnline = paidOnline;
        this.paidCard = paidCard;
        this.paidCash = paidCash;
        this.tipAmount = tipAmount;
        this.tipOnline = tipOnline;
        this.tipCard = tipCard;
        this.tipCash = tipCash;
        this.handlingFee = handlingFee;
      }
    },
    filterDrinkOrder(element) {
      if (window.location.hostname !== 'localhost' && element.orderId.startsWith(this.PAYMENT_TYPE_PAYPAL_SANDBOX)) {
        return false;
      }
      if (this.selectedMonth && getDateStringFromDate(this.selectedMonth, 'YYYY-MM') !== getDateStringFromTimestamp(element.createdAt, 'YYYY-MM')) {
        return false;
      }
      if (this.dateRange && this.dateRange.length === 2 && this.dateRange[0] && this.dateRange[1]) {
        const createdTime = element.createdAt.toDate().getTime();
        if (createdTime < this.dateRange[0].getTime() || createdTime > this.dateRange[1].getTime()) {
          return false;
        }
      }
      if (this.$route.params.userId) {
        return element.organizerId === this.$route.params.userId;
      } else if (this.$route.params.eventId) {
        return element.eventId === this.$route.params.eventId;
      } else {
        return true;
      }
    },
    getUserInfo(userId) {
      const cacheInfo = this.cacheUserList.find(element => element.userId === userId);
      if (cacheInfo) {
        return cacheInfo;
      } else {
        const userInfo = this.userList ? this.userList.find(element => element.userId === userId) : null;
        if (userInfo) {
          this.cacheUserList.push(userInfo);
        }
        return userInfo;
      }
    },
    getEventInfo(eventId) {
      const cacheInfo = this.cacheEventList.find(element => element.eventId === eventId);
      if (cacheInfo) {
        return cacheInfo;
      } else {
        const eventInfo = this.eventList ? this.eventList.find(element => element.eventId === eventId) : null;
        if (eventInfo) {
          this.cacheEventList.push(eventInfo);
        }
        return eventInfo;
      }
    },
    deleteDrinkOrderInfoTask(orderId, eventId) {
      const params = {
        functionName: 'deleteDrinkOrderInfo',
        orderId: orderId,
        eventId: eventId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>